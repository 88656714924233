import React, {FC, ReactNode} from 'react';

import {Box, Typography} from '@mui/material';

export const EmptyState: FC<{
	size?: 'small' | 'medium' | 'large';
	type?: 'card' | 'page';
	description?: string;
	title?: string;
	padding?: number;
	button?: ReactNode;
	image?: ReactNode;
}> = ({
	size = 'medium',
	type = 'page',
	description,
	title,
	padding = 2,
	button,
	image,
}) => {
	let imageWidth;
	let imageHeight;
	let variant: 'h4' | 'h5' | 'h6' = 'h6';

	switch (size) {
		case 'small':
			imageWidth = 40;
			imageHeight = 40;
			break;

		case 'medium':
			imageWidth = 60;
			imageHeight = 60;
			break;

		case 'large':
			imageWidth = 100;
			imageHeight = 100;
			variant = 'h4';
			break;

		default:
			imageWidth = 60;
			imageHeight = 60;
			variant = 'h5';
			break;
	}

	if (type === 'page') {
		return (
			<Box
				style={{transform: 'translate(-50%, -50%)'}}
				position="absolute"
				top="50%"
				left="50%"
				textAlign="center"
			>
				{image && (
					<Box
						sx={{
							width: `${imageWidth}%`,
							height: `${imageHeight}%`,
							marginBottom: title || description ? 2 : 0,
						}}
					>
						{image}
					</Box>
				)}

				{title && (
					<Box mb={!description && button ? 2 : 0.5}>
						<Typography variant={variant}>{title}</Typography>
					</Box>
				)}

				{description && (
					<Typography
						variant="body1"
						sx={{
							marginBottom: button ? 3 : 0,
						}}
					>
						{description}
					</Typography>
				)}

				{button && button}
			</Box>
		);
	}

	if (type === 'card') {
		return (
			<Box padding={padding} textAlign="center">
				{image && (
					<Box
						sx={{
							width: `${imageWidth}%`,
							height: `${imageHeight}%`,
							marginBottom: title || description ? 2 : 0,
						}}
					>
						{image}
					</Box>
				)}

				{title && (
					<Box mb={!description && button ? 2 : 0}>
						<Typography variant={variant}>{title}</Typography>
					</Box>
				)}

				{description && (
					<Typography
						variant="body1"
						sx={{
							marginBottom: button ? 3 : 0,
						}}
					>
						{description}
					</Typography>
				)}

				{button && button}
			</Box>
		);
	}

	return null;
};
