/* eslint-disable no-param-reassign */
import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../store/store';

export const localStorageKey = 'cluster_state';
export type ClusterContext = 'floor_selector' | 'floor_details';
type ClusterState = {
	context: ClusterContext[];
	isClustersVisible?: boolean;
};

const initialState: ClusterState = {
	context: [],
};

const clusterState = JSON.parse(
	localStorage.getItem(localStorageKey) || 'null',
);
export const getPreloadedState = () => {
	if (clusterState === null) {
		return initialState;
	}
	const updatedState: ClusterState = {
		context: clusterState.isClustersVisible
			? ['floor_selector', 'floor_details']
			: clusterState.context || [],
	};
	return updatedState;
};

const slice = createSlice({
	name: 'cluster',
	initialState,
	reducers: {
		toggleClusterVisibility: (
			state: Draft<ClusterState>,
			{payload}: PayloadAction<ClusterContext>,
		) => {
			const clusterSet = new Set(state.context);
			if (clusterSet.has(payload)) {
				clusterSet.delete(payload);
			} else {
				clusterSet.add(payload);
			}
			state.context = Array.from(clusterSet.values());
		},
	},
});

export const {toggleClusterVisibility} = slice.actions;
export const clusterSelector = (state: RootState): ClusterContext[] =>
	state.cluster.context;

export default slice.reducer;
