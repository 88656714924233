import React, {FC} from 'react';

import {Container, Grid} from '@mui/material';

import {WavesLogo} from '../PlatformBackground/PlatformLogo';

export const PublicPageLayout: FC = ({children}) => (
	<Container component="main" maxWidth="sm" sx={{position: 'relative'}}>
		<Grid
			container
			spacing={0}
			alignItems="center"
			justifyContent="center"
			direction="row"
		>
			<Grid item xs={12}>
				<WavesLogo />
			</Grid>
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	</Container>
);
