import {createContext} from 'react';

interface LayoutContextProps {
	scrollTopPosition: boolean;
	setScrollTop?: (scrollTop: number) => void;
}
export const defaultValue: LayoutContextProps = {scrollTopPosition: false};
const LayoutContext = createContext<LayoutContextProps>(defaultValue);

export default LayoutContext;
