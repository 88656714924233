import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit';

import type {RootState} from '../store/store';
import {localStorageKey, toggleClusterVisibility} from './clusterSlice';
import {localStorageEmailKey, storeEmails} from './emailsSlice';
import {FLAGS_KEY, clearFlag, setFlag, setFlags} from './featureFlagsSlice';
import {localStorageThemeKey, setTheme} from './themeSlice';

export const clusterStorageMiddleware = createListenerMiddleware();
clusterStorageMiddleware.startListening({
	matcher: isAnyOf(toggleClusterVisibility),
	effect: (action, listenerApi) => {
		localStorage.setItem(
			localStorageKey,
			JSON.stringify((listenerApi.getState() as RootState).cluster),
		);
	},
});

export const emailsStorageMiddleware = createListenerMiddleware();
clusterStorageMiddleware.startListening({
	matcher: isAnyOf(storeEmails),
	effect: (action, listenerApi) => {
		localStorage.setItem(
			localStorageEmailKey,
			JSON.stringify((listenerApi.getState() as RootState).emails),
		);
	},
});
export const themeStorageMiddleware = createListenerMiddleware();
themeStorageMiddleware.startListening({
	matcher: isAnyOf(setTheme),
	effect: (action, listenerApi) => {
		localStorage.setItem(
			localStorageThemeKey,
			JSON.stringify((listenerApi.getState() as RootState).theme),
		);
	},
});

export const flagStorageMiddleware = createListenerMiddleware();
flagStorageMiddleware.startListening({
	matcher: isAnyOf(setFlags, setFlag, clearFlag),
	effect: (action, listenerApi) => {
		localStorage.setItem(
			FLAGS_KEY,
			JSON.stringify((listenerApi.getState() as RootState).flags),
		);
	},
});
