import React from 'react';

import {ListItem} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Link} from 'react-router-dom';

import NeowitThumb from '../../images/neowit-thumb-primary-32.svg?raw';
import {menuItemSize} from './common';

const useStyles = makeStyles(() => ({
	logoWrapper: {
		width: menuItemSize,
		height: menuItemSize,
		padding: 0,
		margin: '0 auto 16px',
	},
	logo: {
		width: '100%',
		height: '100%',
		'& svg': {
			width: '100%',
			height: '100%',
		},
	},
}));

const LogoItem = () => {
	const styles = useStyles();

	return (
		<ListItem classes={{root: styles.logoWrapper}}>
			<Link
				to="/"
				aria-label="Home"
				className={styles.logo}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{__html: NeowitThumb}}
			/>
		</ListItem>
	);
};

export default LogoItem;
