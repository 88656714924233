/* eslint-disable no-param-reassign */
import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../store/store';

type NeowitTheme = 'light' | 'dark' | 'auto';

export const localStorageThemeKey = 'theme_state';

type ClusterState = {
	themeMode: NeowitTheme;
};

const initialState: ClusterState = {
	themeMode: 'light',
};

const themeState = JSON.parse(
	localStorage.getItem(localStorageThemeKey) || 'null',
);
export const getPreloadedThemeState = () =>
	themeState === null ? initialState : themeState;

const slice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (
			state: Draft<ClusterState>,
			{payload}: PayloadAction<NeowitTheme>,
		) => {
			state.themeMode = payload;
		},
	},
});

export const {setTheme} = slice.actions;
export const themeSelector = (state: RootState): NeowitTheme =>
	state.theme.themeMode;

export default slice.reducer;
