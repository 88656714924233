/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {User} from '../interfaces/User';
import type {RootState} from '../store/store';
import {popNextRouteAfterLogin} from '../utils/login.session';
import {
	setSessionStorageOrgId,
	getSessionStorageOrgIdOrDefault,
	removeSessionStorageOrgId,
} from '../utils/organization.session';
import {auth} from './auth';
import {loginApi} from './login';

type AuthState = {
	user: User | null;
	organizationId: string | null;
};

const slice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
		organizationId: null,
	} as AuthState,
	reducers: {
		setOrganizationId: (
			state,
			{
				payload: {organizationId},
			}: PayloadAction<{organizationId: string}>,
		) => {
			if (state.user) {
				setSessionStorageOrgId(state.user, organizationId);
			}
			auth.setNamespace(organizationId);
			state.organizationId = organizationId;
		},
		clearCredentials: (state) => {
			if (state.user) {
				removeSessionStorageOrgId(state.user);
			}
			state.user = null;
			state.organizationId = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			loginApi.endpoints.getLoggedInUser.matchFulfilled,
			(state, {payload}) => {
				state.user = payload.user;
				state.organizationId = getSessionStorageOrgIdOrDefault(
					payload.user,
				);
				auth.setNamespace(state.organizationId);
				auth.setAccessToken(payload.accessToken);
			},
		);
		builder.addMatcher(
			loginApi.endpoints.logout.matchFulfilled,
			(state) => {
				if (state.user) {
					removeSessionStorageOrgId(state.user);
				}
				state.user = null;
				state.organizationId = null;
				auth.setAccessToken(null);
				auth.setNamespace(null);
				popNextRouteAfterLogin(''); // just to make sure
				window.location.href = '/login';
			},
		);
		builder.addMatcher(loginApi.endpoints.logout.matchRejected, (state) => {
			state.user = null;
			state.organizationId = null;
			auth.setAccessToken(null);
			auth.setNamespace(null);
			popNextRouteAfterLogin(''); // just to make sure
			window.location.href = '/login';
		});
		builder.addMatcher(
			loginApi.endpoints.login.matchFulfilled,
			(state, {payload}) => {
				if (payload.location && payload.location.length > 0) {
					popNextRouteAfterLogin(''); // SAML redirect, pop the route
					window.location.href = payload.location;
				} else {
					state.user = payload.user;
					state.organizationId = getSessionStorageOrgIdOrDefault(
						payload.user,
					);
					auth.setNamespace(state.organizationId);
					auth.setAccessToken(payload.accessToken);
				}
			},
		);
	},
});

export const {clearCredentials, setOrganizationId} = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState): User | null =>
	state.auth.user;
export const selectCurrentOrganizationId = (state: RootState): string | null =>
	state.auth.organizationId;
