import {emphasize} from '@mui/system';

export const LIGHT_GREY = '#E6E6E6';
export const LIGHT_GREY_2 = emphasize(LIGHT_GREY, 0.6);
export const MEDIUM_GREY = '#8D8D8D';
export const DARK_GREY = '#2D2D2D';
export const DARK_GREY_2 = '#5C5C5C';
export const GREY_BG = '#4f4f4f';
export const BLUE = '#06DFDF';
export const RGB_BLUE = [6, 223, 223];
export const DARK_BLUE = '#05BCBC';
export const RGB_DARK_BLUE = [5, 188, 188];
export const DARK_BLUE_PLUS = '#046666';
export const RGB_DARK_BLUE_PLUS = [4, 102, 102];
export const YELLOW = '#E8AF1D';
export const YELLOW2 = emphasize(YELLOW, 0.5);
export const RED = '#BA1700';
export const COOL_GREY_2 = '#CDCBD6';
export const VIOLET = '#11172A';
export const ROUGE = '#BC055D';
export const ROUGE_2 = emphasize(ROUGE, 0.4);
export const WHITE = '#FFFFFF';
export const BOOKING_USED = '#EC8181';

export const PINKISH_RED = '#FFB4AB';
export const LIGHT_GREEN = '#CCE8E7';
export const BACKGROUND_GREY = '#EFF1F0';
export const BACKGROUND_LIGHT_GREY = '#FAFDFC';
export const BACKGROUND_FILTER_TRANSPARENT = '#006A6B1F';
export const LIGHT_GREY_TRANSPARENT = '#1C1B1F1F';

// Design system: https://www.figma.com/file/7EDR07Cgp6UaY2ohJ643rC/Neowit-MVP%2B1?type=design&node-id=4735-27845&mode=design&t=gSHo4oH4fk12Hjrd-0

// Primary
export const PRIMARY_100 = '#FFFFFF';
export const PRIMARY_99 = '#F1FFFE';
export const PRIMARY_95 = '#ADFFFE';
export const PRIMARY_90 = '#48FAF9';
export const PRIMARY_80 = '#00DDDD';
export const PRIMARY_70 = '#00BEBE';
export const PRIMARY_60 = '#00A1A1';
export const PRIMARY_50 = '#008585';
export const PRIMARY_40 = '#006A6A';
export const PRIMARY_30 = '#004F4F';
export const PRIMARY_20 = '#003737';
export const PRIMARY_10 = '#002020';
export const PRIMARY_0 = '#000000';

// Secondary
export const SECONDARY_100 = '#FFFFFF';
export const SECONDARY_99 = '#F1FFFE';
export const SECONDARY_95 = '#DAF6F5';
export const SECONDARY_90 = '#CCE8E7';
export const SECONDARY_80 = '#B0CCCB';
export const SECONDARY_70 = '#95B0B0';
export const SECONDARY_60 = '#7B9695';
export const SECONDARY_50 = '#627C7B';
export const SECONDARY_40 = '#4A6363';
export const SECONDARY_30 = '#324B4B';
export const SECONDARY_20 = '#1B3534';
export const SECONDARY_10 = '#051F1F';
export const SECONDARY_0 = '#000000';

// Error
export const ERROR_100 = '#FFFFFF';
export const ERROR_99 = '#FFFBFF';
export const ERROR_95 = '#FFEDEA';
export const ERROR_90 = '#FFDAD6';
export const ERROR_80 = '#FFB4AB';
export const ERROR_70 = '#FF897D';
export const ERROR_60 = '#FF5449';
export const ERROR_50 = '#DE3730';
export const ERROR_40 = '#BA1A1A';
export const ERROR_30 = '#93000A';
export const ERROR_20 = '#690005';
export const ERROR_10 = '#410002';
export const ERROR_0 = '#000000';

// Neutral
export const NEUTRAL_100 = '#FFFFFF';
export const NEUTRAL_99 = '#FAFDFC';
export const NEUTRAL_95 = '#EFF1F0';
export const NEUTRAL_90 = '#E0E3E2';
export const NEUTRAL_80 = '#C4C7C6';
export const NEUTRAL_70 = '#A9ACAB';
export const NEUTRAL_60 = '#8E9191';
export const NEUTRAL_50 = '#747877';
export const NEUTRAL_40 = '#5B5F5F';
export const NEUTRAL_30 = '#444747';
export const NEUTRAL_20 = '#2D3131';
export const NEUTRAL_10 = '#191C1C';
export const NEUTRAL_0 = '#000000';

// contrast colors to BLUE
export const CONTRAST_COLORS = [
	'#39df55',
	'#06dfdf',
	'#cc2b38',
	'#bb41f8',
	'#02b3b4',
	'#ffc700',
];
