/* eslint-disable no-param-reassign */
import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../store/store';

export const localStorageEmailKey = 'emails_state';

type EmailsState = {
	emails: string[];
};

const initialState: EmailsState = {
	emails: [],
};

const emailsState = JSON.parse(
	localStorage.getItem(localStorageEmailKey) || 'null',
);
export const getPreloadedEmailsState = () =>
	emailsState === null ? initialState : emailsState;

const slice = createSlice({
	name: 'emails',
	initialState,
	reducers: {
		storeEmails: (
			state: Draft<EmailsState>,
			{payload}: PayloadAction<string[]>,
		) => {
			state.emails = Array.from<string>(
				new Set([...state.emails, ...payload]),
			);
		},
	},
});

export const {storeEmails} = slice.actions;
export const storedEmailsSelector = (state: RootState): string[] =>
	state.emails.emails;

export default slice.reducer;
