import React, {useEffect, useState} from 'react';

import FilterInput from './FilterInput';

export interface FilterProps<T> {
	entities: T[];
	label?: string;
	setFiltered: (filtered: T[]) => void;
	filter: (tokens: string[], entities: T[]) => T[];
}

export const Filter = <T,>({
	entities,
	setFiltered,
	filter,
	label,
}: FilterProps<T>) => {
	const [query, setQuery] = useState('');
	const [tokens, setTokens] = useState<string[]>([]);
	const handleQuery = (value: string) => {
		setTokens(
			value
				.split(' ')
				.filter((s) => Boolean(s))
				.map((s) => s.toLocaleLowerCase()),
		);
		setQuery(value);
	};
	useEffect(
		() => setFiltered(filter(tokens, entities)),
		[entities, filter, tokens, setFiltered],
	);

	return <FilterInput value={query} onChange={handleQuery} label={label} />;
};
