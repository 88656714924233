import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {
	selectCurrentOrganizationId,
	selectCurrentUser,
} from '../services/authSlice';

export const useAuth = () => {
	const orgId = useSelector(selectCurrentOrganizationId);
	const user = useSelector(selectCurrentUser);
	return useMemo(() => ({user, orgId}), [user, orgId]);
};
