import React, {FC} from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {Logo} from '../Logo/Logo';

const useStyles = makeStyles((theme) => ({
	logo: {
		display: 'flex',
		flexGrow: 1,
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
	},
}));

export const WavesLogo: FC = () => {
	const classes = useStyles();
	return <Logo className={classes.logo} variant="positive" />;
};
