import {Mutex} from 'async-mutex';

import type {User} from '../interfaces/User';
import {API_SERVER} from '../utils/apiServer';
import log from './logging';

const apiHost = API_SERVER.toString();

export const apiURL = (
	path: string,
	params?: string[][] | Record<string, string> | string | URLSearchParams,
	orgId?: string | null,
): string => {
	const url = new URL(path, apiHost);
	if (params) {
		url.search = new URLSearchParams(params).toString();
		if (orgId) {
			url.search = `${url.search}&orgId=${orgId}`;
		}
	} else if (orgId) {
		url.search = `?orgId=${orgId}`;
	}
	return url.toString();
};

export const getEnvironment = (): 'dev' | 'staging' | 'prod' => {
	switch (apiHost) {
		case 'https://dev.neow.it':
			return 'dev';
		case 'https://staging.neow.it':
			return 'staging';
		case 'https://app.neowit.io':
			return 'prod';
		default:
			throw new Error('Invalid hook host');
	}
};

const hookHost = (): string => {
	switch (apiHost) {
		case 'https://dev.neow.it':
			return 'https://hook.dev.neow.it';
		case 'https://staging.neow.it':
			return 'https://hook.staging.neow.it';
		case 'https://app.neowit.io':
			return 'https://hook.neowit.io';
		default:
			throw new Error('Invalid hook host');
	}
};

export const hookURL = (
	path: string,
	params?: string[][] | Record<string, string> | string | URLSearchParams,
): string => {
	const url = new URL(path, hookHost());
	if (params) {
		url.search = new URLSearchParams(params).toString();
	}
	return url.toString();
};

export class Auth {
	private accessToken?: string;

	private orgId: string | null = null;

	private mu = new Mutex();

	setAccessToken(token: string | null) {
		if (token) {
			this.accessToken = token;
		} else {
			delete this.accessToken;
		}
	}

	async getAccessToken(): Promise<string | undefined> {
		try {
			await this.mu.acquire();
			return this.accessToken;
		} finally {
			await this.mu.release();
		}
	}

	async refreshAccessToken(): Promise<
		| {status: 'ok'; accessToken: string; user: User}
		| {status: 'failed'; notAuthenticated: boolean}
	> {
		try {
			await this.mu.acquire();
			return this.doRefreshAccessToken();
		} finally {
			await this.mu.release();
		}
	}

	setNamespace(id: string | null) {
		this.orgId = id;
	}

	getNamespace() {
		return this.orgId;
	}

	getClientAuthConfig() {
		return {
			getAccessToken: () => this.getAccessToken(),
			getNamespace: () => this.getNamespace(),
			refresh: async () => {
				await this.refreshAccessToken();
			},
		};
	}

	private async doRefreshAccessToken(): Promise<
		| {status: 'ok'; accessToken: string; user: User}
		| {status: 'failed'; notAuthenticated: boolean}
	> {
		const url = apiURL('/api/auth/v1/token');
		try {
			const res = await window.fetch(url, {
				method: 'PUT',
				mode: 'cors',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					device: navigator.userAgent,
				}),
			});
			switch (res.status) {
				case 200: {
					const body = (await res.json()) as {
						accessToken: string;
						user: User;
					};
					this.accessToken = body.accessToken;
					return {
						status: 'ok',
						accessToken: this.accessToken,
						user: body.user,
					};
				}
				case 401:
				case 403:
					delete this.accessToken;
					return {status: 'failed', notAuthenticated: true};
				default:
					throw new Error('Unable refresh token');
			}
		} catch (e) {
			log.error('Token refresh failed due to exception=%s', e);
			delete this.accessToken;
			return {status: 'failed', notAuthenticated: false};
		}
	}
}

export const auth = new Auth();
