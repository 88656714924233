import React, {FC, UIEvent, useContext, useMemo} from 'react';

import {Backdrop, CircularProgress, Container, useTheme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {FlagsProvider} from 'flagged';

import LayoutContext from '../../context/LayoutContext';
import LayoutProvider from '../../context/LayoutProvider';
import {useAuth} from '../../hooks/useAuth';
import {useMediaExtended} from '../../hooks/useMediaExtended';
import type {User} from '../../interfaces/User';
import {useGetLoggedInUserQuery} from '../../services/login';
import {Sidebar} from '../AppBar/Sidebar';
import ReloadError from '../Errors/ReloadError';
import {WavesBackground} from '../PlatformBackground/PlatformBackground';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer - 1,
	},
}));

const MainView: FC<{user: User | null}> = ({user, children}) => {
	const classes = useStyles();
	const theme = useTheme();
	const isLargeEnoughToShowSidebar = useMediaExtended('md');
	const {setScrollTop} = useContext(LayoutContext);

	return (
		<div className={classes.root}>
			{isLargeEnoughToShowSidebar && !!user && <Sidebar />}
			<main
				className={classes.content}
				onScroll={(event: UIEvent<HTMLElement>) => {
					const {scrollTop} = event.currentTarget;
					if (setScrollTop) {
						setScrollTop(scrollTop);
					}
				}}
			>
				<Container
					maxWidth={false}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100%',
						paddingTop: {
							xs: theme.spacing(1),
							md: theme.spacing(4),
						},
						paddingBottom: {
							xs: theme.spacing(1),
							md: theme.spacing(4),
						},
					}}
				>
					{!user && <WavesBackground />}
					{children as string}
				</Container>
			</main>
		</div>
	);
};

export const Layout: React.FC = ({children}) => {
	const classes = useStyles();
	const {user} = useAuth();
	const {isLoading, error, refetch} = useGetLoggedInUserQuery(undefined, {
		skip: !!user,
	});
	const features = useMemo(() => {
		const out: {[key: string]: boolean} = {};
		user?.features.forEach((each) => {
			out[each] = true;
		});
		return out;
	}, [user]);

	if (isLoading) {
		return (
			<Backdrop className={classes.backdrop} open>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}
	if (error) {
		const isNormalAuthError =
			'data' in error && (error.status === 401 || error.status === 403);
		if (!isNormalAuthError) {
			return (
				<ReloadError
					title="Failed to log you in at this time"
					description="Please try again later and check your network connection."
					reload={refetch}
				/>
			);
		}
	}
	return (
		<FlagsProvider features={features}>
			<LayoutProvider>
				<MainView user={user}>{children}</MainView>
			</LayoutProvider>
		</FlagsProvider>
	);
};
