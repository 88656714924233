import React from 'react';

import {CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from 'react-redux';

import type {Organization} from '../../interfaces/Organization';
import {selectCurrentOrganizationId} from '../../services/authSlice';
import {useGetOrganizationsQuery} from '../../services/organizations';
import {DetailedFetchAPIError} from '../Forms/FormAPIError';
import OrganizationTree from '../OrganizationTree/OrganizationTree';
import BaseModal from './BaseModal';

const useStyles = makeStyles({
	dialogPaper: {
		minHeight: '60vh',
		maxHeight: '60vh',
	},
});

export const OrganizationSwitcherDialog: React.FC<{
	toggleModal: (state: boolean) => void;
	onSelect: (org: Organization, notify: boolean, redirect: boolean) => void;
}> = ({toggleModal, onSelect}) => {
	const classes = useStyles();
	const orgId = useSelector(selectCurrentOrganizationId);
	const {data, error, isLoading, refetch} = useGetOrganizationsQuery({
		params: {recurse: 'true'},
		opts: {useOrgId: false},
	});
	return (
		<BaseModal
			title="Change organization"
			classes={{paper: classes.dialogPaper}}
			fullWidth
			maxWidth="md"
			scroll="paper"
			toggleModal={toggleModal}
		>
			<OrganizationTree
				data={data}
				selectedOrgId={orgId}
				title="Choose an organization below to view or manage its contents."
				onSelect={(org) => onSelect(org, true, true)}
			/>
			{isLoading && <CircularProgress />}
			<DetailedFetchAPIError
				title="Failed to list organizations"
				error={error}
				refetch={refetch}
			/>
		</BaseModal>
	);
};
