import {useMemo} from 'react';

import {Feature} from '../enums/Feature';
import {useAuth} from './useAuth';

export const useHomeRoute = (): string => {
	const {user} = useAuth();
	return useMemo(() => {
		if (user?.features.includes(Feature.Admin)) {
			return '/locations';
		}
		if (user) {
			return '/workspaces';
		}
		return '/login';
	}, [user]);
};
