export enum Feature {
	Admin = 'admin',
	Workspace = 'workspace',
	Dashboard = 'dashboard',
}

export const ALL_FEATURES: Feature[] = [
	Feature.Admin,
	Feature.Workspace,
	Feature.Dashboard,
];
