import React, {FC} from 'react';

import {Apartment, Insights, WorkspacesOutlined} from '@mui/icons-material';
import {List} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {Feature} from '../../enums/Feature';
import {useAuth} from '../../hooks/useAuth';
import useModal from '../../hooks/useModal';
import type {Organization} from '../../interfaces/Organization';
import {api} from '../../services/api';
import {setOrganizationId} from '../../services/authSlice';
import {NotificationsService} from '../../services/Notifications.service';
import {OrganizationSwitcherDialog} from '../Dialogs/OrganizationSwitcherDialog';
import {BarChart, Devices, Settings, Transfer} from '../Icon';
import {AccountMenuButton} from './AccountMenuButton';
import {drawerWidth} from './common';
import LogoItem from './LogoItem';
import MenuButton from './MenuButton';
import MenuItem from './MenuItem';
import SelectThemeButton from './SelectThemeButton';
import Spacer from './Spacer';

const useStyles = makeStyles(() => ({
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		padding: '16px',
		background: 'transparent',
		border: 'none',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	paper: {
		height: '100%',
		padding: '0 8px',
	},
}));

const MenuList: FC = () => {
	const styles = useStyles();
	const {pathname} = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [modalVisible, toggleModal] = useModal();
	const {user} = useAuth();

	const handleSelectOrg = (
		selected: Organization,
		notify = true,
		redirect = false,
	) => {
		if (notify) {
			NotificationsService.success(`${selected.name} selected!`);
		}
		if (redirect) {
			dispatch(setOrganizationId({organizationId: selected.id}));
			dispatch(api.util.resetApiState());
			toggleModal(false);
			navigate('/');
		}
	};

	return (
		<List classes={{root: clsx(styles.list)}}>
			<LogoItem />
			<MenuItem
				pathname={pathname}
				to="/workspaces"
				title="Workspaces"
				icon={<WorkspacesOutlined />}
				feature={Feature.Workspace}
				prefix
			/>
			<MenuItem
				pathname={pathname}
				to="/locations"
				title="Buildings"
				feature={Feature.Admin}
				icon={<Apartment />}
				prefix
			/>
			<MenuItem
				pathname={pathname}
				to="/devices"
				title="Devices"
				icon={<Devices />}
				feature={Feature.Admin}
				prefix
			/>
			<MenuItem
				pathname={pathname}
				to="/dashboard"
				title="Dashboard"
				icon={<BarChart />}
				feature={Feature.Dashboard}
				prefix
			/>
			<MenuItem
				pathname={pathname}
				to="/insights"
				title="Insights"
				icon={<Insights />}
				feature={Feature.Dashboard}
				prefix
			/>
			<Spacer />
			<MenuItem
				pathname={pathname}
				to="/settings"
				title="Settings"
				icon={<Settings />}
				feature={Feature.Admin}
				prefix
			/>
			<MenuButton
				icon={<Transfer />}
				onClick={() => toggleModal(true)}
				title="Select organization"
				feature={Feature.Admin}
			/>
			<SelectThemeButton />
			<AccountMenuButton user={user} />
			{modalVisible && (
				<OrganizationSwitcherDialog
					toggleModal={toggleModal}
					onSelect={handleSelectOrg}
				/>
			)}
		</List>
	);
};

export default MenuList;
