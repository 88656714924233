import {configureStore} from '@reduxjs/toolkit';

import {api} from '../services/api';
import authReducer from '../services/authSlice';
import clusterReducer, {getPreloadedState} from '../services/clusterSlice';
import emailsReducer, {getPreloadedEmailsState} from '../services/emailsSlice';
import featureFlagsReducer, {
	getPreloadedFlagsState,
} from '../services/featureFlagsSlice';
import {loginApi} from '../services/login';
import {
	clusterStorageMiddleware,
	emailsStorageMiddleware,
	flagStorageMiddleware,
	themeStorageMiddleware,
} from '../services/storageMiddlewares';
import themeReducer, {getPreloadedThemeState} from '../services/themeSlice';

export const setupStore = () =>
	configureStore({
		preloadedState: {
			cluster: getPreloadedState(),
			emails: getPreloadedEmailsState(),
			theme: getPreloadedThemeState(),
			flags: getPreloadedFlagsState(),
		},
		reducer: {
			[api.reducerPath]: api.reducer,
			[loginApi.reducerPath]: loginApi.reducer,
			auth: authReducer,
			cluster: clusterReducer,
			emails: emailsReducer,
			theme: themeReducer,
			flags: featureFlagsReducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(
				loginApi.middleware,
				api.middleware,
				clusterStorageMiddleware.middleware,
				emailsStorageMiddleware.middleware,
				themeStorageMiddleware.middleware,
				flagStorageMiddleware.middleware,
			),
	});
