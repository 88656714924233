function getApiServer() {
	if (process.env.REACT_APP_API_SERVER) {
		return new URL(process.env.REACT_APP_API_SERVER);
	}
	if (window.location.hostname.endsWith('neowit-dev.pages.dev')) {
		return new URL('https://dev.neow.it/');
	}
	return new URL(window.location.origin);
}

export const API_SERVER = getApiServer();
