import log from '../services/logging';

const key = 'login:next';

export const setNextRouteAfterLogin = (next: string) => {
	try {
		log.info(`sessionStorage:set next route after login ${next}`);
		if (next.startsWith('/login') || next.startsWith('/logout')) {
			window.sessionStorage.setItem(key, next);
		} else {
			window.sessionStorage.setItem(key, next);
		}
	} catch (e) {
		log.error('sessionStorage:set Failed to set', e);
	}
};

export const popNextRouteAfterLogin = (defaultURL: string): string => {
	try {
		log.info('sessionStorage:pop next route after login');
		const next = window.sessionStorage.getItem(key);
		window.sessionStorage.removeItem(key);
		return next || defaultURL;
	} catch (e) {
		log.error('sessionStorage:get Failed to get', e);
		return defaultURL;
	}
};

export const getNextRouteAfterLogin = (defaultURL: string): string => {
	try {
		log.info('sessionStorage:get next route after login');
		const next = window.sessionStorage.getItem(key);
		return next || defaultURL;
	} catch (e) {
		log.error('sessionStorage:get Failed to get', e);
		return defaultURL;
	}
};
