import {useEffect, useMemo} from 'react';

import {NotificationsService} from '../services/Notifications.service';
import type APIError from '../types/APIError';

export type MutationStatus = {
	isLoading: boolean;
	isSuccess: boolean;
	error?: APIError;
	title: string;
	reset: () => void;
};

export type MutationRequest<T> = {
	isLoading: boolean;
	isSuccess: boolean;
	data?: T;
	error?: APIError;
	reset: () => void;
};

const useMutation = <T>(
	{isSuccess, isLoading, error, reset, data}: MutationRequest<T>,
	errorTitle: string,
	successMessage: string,
	onSuccess?: (data?: T) => void,
): MutationStatus => {
	useEffect(() => {
		if (isSuccess) {
			if (successMessage) {
				NotificationsService.success(successMessage);
			}
			if (onSuccess) {
				onSuccess(data);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, data /* , onSuccess */]);
	return useMemo(
		() => ({isSuccess, isLoading, error, reset, title: errorTitle}),
		[isSuccess, isLoading, error, reset, errorTitle],
	);
};

export default useMutation;
