import {
	type BaseQueryFn,
	type FetchArgs,
	fetchBaseQuery,
	type FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import type {APIOpts} from '../types/APIOpts';
import {API_SERVER} from '../utils/apiServer';
import {setNextRouteAfterLogin} from '../utils/login.session';
import {auth} from './auth';
import {clearCredentials} from './authSlice';

interface QueryArgs extends FetchArgs {
	opts?: APIOpts;
}

const baseUrl = API_SERVER.toString();
export const baseQuery = fetchBaseQuery({
	baseUrl,
	credentials: 'include',
	mode: baseUrl !== window.location.origin ? 'cors' : undefined,
	prepareHeaders: async (headers) => {
		const token = await auth.getAccessToken();
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

const argsToFetchArgs = (args: QueryArgs): FetchArgs => {
	const {opts, params, ...rest} = args;
	const useOrgId = opts ? opts.useOrgId : true;
	if (useOrgId) {
		const orgId = auth.getNamespace();
		if (!orgId) {
			return args;
		}
		const current = params || {};
		return {
			...rest,
			params: {
				orgId,
				...current,
			},
		};
	}
	return args;
};

// FIXME: Attempt at better typings for our errors!
export const customFetchBase =
	(): BaseQueryFn<QueryArgs, unknown, FetchBaseQueryError> =>
	async (args, api, extraOptions) => {
		const fetchArgs = argsToFetchArgs(args);
		const result = await baseQuery(fetchArgs, api, extraOptions);
		if (!result.error) {
			return result;
		}
		if (result.error.status === 401) {
			const refresh = await auth.refreshAccessToken();
			if (refresh.status === 'ok') {
				fetchArgs.headers = {
					...(fetchArgs.headers || {}),
					authorization: `Bearer ${refresh.accessToken}`,
				};
				return baseQuery(fetchArgs, api, extraOptions);
			}
			if (refresh.notAuthenticated) {
				// FIXME(vfr): not sure about this...
				api.dispatch(clearCredentials());
				setNextRouteAfterLogin(window.location.pathname);
				window.location.href = '/login';
			}
		}
		return result;
	};
