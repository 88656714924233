import React, {FC} from 'react';

import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

import {HomeFill} from '../Icon';
import {EmptyState} from './EmptyState';

export const NotFound: FC = () => (
	<EmptyState
		size="large"
		image={<Typography variant="h1">404</Typography>}
		title="Page doesn’t exist."
		description="The page you’re trying to access doesn’t exist."
		button={
			<Button
				variant="contained"
				color="primary"
				component={Link}
				to="/"
				startIcon={<HomeFill />}
			>
				Home
			</Button>
		}
	/>
);
