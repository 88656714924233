import React, {MouseEventHandler} from 'react';

import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Feature as FlaggedFeature} from 'flagged';

import {Feature} from '../../enums/Feature';
import {useMediaExtended} from '../../hooks/useMediaExtended';
import {menuItemSize} from './common';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 4,
		padding: 0,
		margin: '0 auto 16px',
		'&:last-child': {
			margin: '0 auto',
		},
		'&$selected': {
			backgroundColor: 'transparent',
		},
		'&$selected:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		color: theme.palette.secondary.main,
		fontWeight: 500,
		fontSize: 18,
		width: menuItemSize,
		minWidth: 0,
		justifyContent: 'center',
		alignItems: 'center',
	},
	selected: {
		backgroundColor: 'transparent',
	},
}));

interface MenuButtonProps {
	icon: React.ReactNode;
	title: string;
	onClick?: MouseEventHandler;
	// eslint-disable-next-line react/no-unused-prop-types
	feature?: Feature;
}

const MenuButtonInner: React.FC<MenuButtonProps> = ({icon, onClick, title}) => {
	const classes = useStyles();
	const isMenuMobile = useMediaExtended('md');

	return (
		<ListItem
			onClick={onClick}
			classes={{
				root: classes.root,
				selected: classes.selected,
			}}
		>
			<ListItemButton sx={{paddingLeft: {md: 0}, paddingRight: {md: 0}}}>
				{isMenuMobile ? (
					<Tooltip title={title} arrow placement="right">
						<ListItemIcon classes={{root: classes.icon}}>
							{icon}
						</ListItemIcon>
					</Tooltip>
				) : (
					<>
						<ListItemIcon classes={{root: classes.icon}}>
							{icon}
						</ListItemIcon>
						<ListItemText>{title}</ListItemText>
					</>
				)}
			</ListItemButton>
		</ListItem>
	);
};

const MenuButton: React.FC<MenuButtonProps> = (props) => {
	const {feature} = props;
	if (feature) {
		return (
			<FlaggedFeature name={feature}>
				<MenuButtonInner {...props} />
			</FlaggedFeature>
		);
	}
	return <MenuButtonInner {...props} />;
};

export default MenuButton;
