import React, {FC} from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';

import {NotFound} from '../components/Errors';
import {useHomeRoute} from '../hooks/useHomeRoute';
import {PrivateRoute} from './PrivateRoute';
import routes from './routes.resource';

export const RootRoutes: FC = () => {
	const defaultRoute = useHomeRoute();
	return (
		<Routes>
			<Route path="/" element={<Navigate to={defaultRoute} replace />} />
			<Route
				path="settings"
				element={<Navigate to="integrations" replace />}
			/>
			{routes.map(
				({path, component: Component, isPrivate, feature, key}) =>
					isPrivate ? (
						<Route
							key={key}
							path={path}
							element={
								<PrivateRoute feature={feature}>
									<Component />
								</PrivateRoute>
							}
						/>
					) : (
						<Route key={key} path={path} element={<Component />} />
					),
			)}
			<Route element={<NotFound />} path="*" />
		</Routes>
	);
};
