export const tokenizeString = (str: string): string[] =>
	str
		.split(' ')
		.filter((s) => Boolean(s))
		.map((s) => s.toLocaleLowerCase());

export const tokenizeStringMatches = (
	str: string,
	tokens: string[],
): boolean => {
	if (tokens.length === 0) {
		return true;
	}
	const lower = str.toLocaleLowerCase();
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < tokens.length; i++) {
		if (!lower.includes(tokens[i])) {
			return false;
		}
	}
	return true;
};
