import React, {FC} from 'react';

import {Drawer, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {drawerWidth} from './common';
import MenuList from './MenuList';

const useStyles = makeStyles(() => ({
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		padding: '16px',
		background: 'transparent',
		border: 'none',
	},
	paper: {
		height: '100%',
		padding: '0 8px',
		borderRadius: '12px',
	},
}));

export const Sidebar: FC = () => {
	const styles = useStyles();

	return (
		<Drawer
			variant="permanent"
			classes={{
				paper: styles.drawerPaper,
			}}
		>
			<Paper classes={{root: styles.paper}} elevation={2}>
				<MenuList />
			</Paper>
		</Drawer>
	);
};
