import {createApi} from '@reduxjs/toolkit/query/react';

import {customFetchBase} from './apiCustomFetch';

export const TagTypes = {
	BookingEvents: 'BookingEvents',
	BookingSchedules: 'BookingSchedules',
	BookingMailboxes: 'BookingMailboxes',
	Dashboards: 'Dashboards',
	IdentityProviders: 'IdentityProviders',
	IdentityProvidersToken: 'IdentityProvidersToken',
	Integrations: 'Integrations',
	Organizations: 'Organizations',
	UserProfiles: 'UserProfiles',
	Users: 'Users',
	ServiceAccounts: 'ServiceAccounts',
	SensorTypes: 'SensorTypes',
	Devices: 'Devices',
	Spaces: 'Spaces',
	Favorites: 'Favorites',
	Workspaces: 'Workspaces',
	WorkspaceProfiles: 'WorkspaceProfiles',
	Cluster: 'Cluster',
	Tags: 'Tags',
	InsightsReports: 'InsightsReports',
	InsightsReportShares: 'InsightsReportShares',
	EmailSenderStatus: 'EmailSenderStatus',
} as const;

export const api = createApi({
	reducerPath: 'api',
	baseQuery: customFetchBase(),
	tagTypes: Object.values(TagTypes),
	endpoints: () => ({}),
});
