import React, {FC, useMemo, useState} from 'react';

import {
	Badge,
	Box,
	DialogContentText,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';

import type {Organization} from '../../interfaces/Organization';
import {tokenizeStringMatches} from '../../utils/tokenizeString';
import {Filter} from '../Forms/Filter';

interface OrgItem extends Organization {
	parentNames: string[];
}

const sortOrgItems = (orgs: OrgItem[], orgId: string | null): OrgItem[] =>
	orgs.sort((a, b) => {
		if (orgId && a.id === orgId) {
			return -1;
		}
		if (orgId && b.id === orgId) {
			return 1;
		}
		return a.name.localeCompare(b.name);
	});

const orgsToOrgItems = (
	orgs: Organization[],
	orgId: string | null,
): OrgItem[] => {
	const flat = orgs.reduce(
		(map, org) => {
			// eslint-disable-next-line no-param-reassign
			map[org.id] = org;
			return map;
		},
		{} as {[id: string]: Organization},
	);
	return sortOrgItems(
		orgs.map((org): OrgItem => {
			const parentNames: string[] = [];
			let parent = flat[org.parent];
			while (parent) {
				parentNames.push(parent.name);
				parent = flat[parent.parent];
			}
			parentNames.reverse();
			return {parentNames, ...org};
		}),
		orgId,
	);
};

const Row: FC<{
	org: OrgItem;
	index: number;
	selected: string | null;
	onSelect: (org: Organization) => void;
}> = ({org, index, selected, onSelect}) => {
	const isSelected = org.id === selected;
	return (
		<TableRow
			hover
			sx={{cursor: 'pointer'}}
			onClick={() => onSelect(org)}
			aria-describedby="org-dialog-title"
			tabIndex={index}
			selected={isSelected}
		>
			<TableCell>
				<Typography variant="body2" component="span">
					{org.name}
				</Typography>
				<br />
				<Typography variant="body1" component="span">
					{org.parentNames.length === 0
						? 'Your organization'
						: org.parentNames.join(' / ')}
				</Typography>
			</TableCell>
			<TableCell>
				{isSelected && <Badge color="primary" badgeContent="Current" />}
			</TableCell>
		</TableRow>
	);
};

const ListView: FC<{
	orgs: OrgItem[];
	selected: string | null;
	onSelect: (org: Organization) => void;
}> = ({orgs, selected, onSelect}) => (
	<TableContainer>
		<Table>
			<TableBody>
				{orgs.map((org, i) => (
					<Row
						key={org.id}
						org={org}
						index={i}
						onSelect={onSelect}
						selected={selected}
					/>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

const filterOrgs = (tokens: string[], entities: OrgItem[]): OrgItem[] => {
	if (tokens.length === 0) {
		return entities;
	}
	return entities.filter((d) => tokenizeStringMatches(d.name, tokens));
};

export interface OrganizationItem extends Organization {
	selectDisabled?: boolean;
	selectDisabledReason?: string;
}

const OrganizationTree: FC<{
	title: string;
	data: OrganizationItem[] | undefined;
	selectedOrgId: string | null;
	onSelect: (org: Organization) => void;
}> = ({title, data, onSelect, selectedOrgId}) => {
	const organizations = useMemo(
		() => orgsToOrgItems(data || [], selectedOrgId),
		[data, selectedOrgId],
	);
	const [filtered, setFiltered] = useState<OrgItem[]>([]);

	return (
		<>
			<Box mb={1} mt={0.5}>
				<Filter<OrgItem>
					entities={organizations}
					filter={filterOrgs}
					setFiltered={setFiltered}
					label="Search"
				/>
			</Box>
			<DialogContentText id="org-dialog-description" mb={1}>
				{title}
			</DialogContentText>
			<ListView
				orgs={filtered}
				selected={selectedOrgId}
				onSelect={onSelect}
			/>
		</>
	);
};

export default OrganizationTree;
