import React from 'react';

import {Fade, LinearProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	root: {
		height: 2,
	},
}));
const Loading = () => {
	const styles = useStyles();
	return (
		<Fade in timeout={{enter: 2000, appear: 2000}}>
			<LinearProgress classes={{root: styles.root}} />
		</Fade>
	);
};

export default Loading;
