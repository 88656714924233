import React from 'react';

import {Button, Typography} from '@mui/material';

import {FLAGS_KEY} from '../../services/featureFlagsSlice';
import {Refresh} from '../Icon';
import {EmptyState} from './EmptyState';

export class ErrorBoundry extends React.Component<
	unknown,
	{hasError: boolean; error?: Error}
> {
	constructor(props: unknown) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error: Error) {
		return {hasError: true, error};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		// eslint-disable-next-line no-console
		console.error('ERROR', error, info);
	}

	render() {
		const {hasError, error} = this.state;
		if (hasError) {
			return (
				<EmptyState
					image={
						<Typography
							variant="h1"
							component="h1"
							style={{
								display: 'flex',
								alignContent: 'center',
								textAlign: 'center',
							}}
						>
							500
						</Typography>
					}
					title="Something went wrong"
					description="Don't worry, this is our fault..."
					button={
						<>
							<p>Name: {error?.name}</p>
							<p>Message: {error?.message}</p>
							<p>Stack: {error?.stack}</p>
							<Button
								variant="contained"
								color="primary"
								startIcon={<Refresh />}
								onClick={() => {
									window.location.reload();
								}}
							>
								Reload
							</Button>
							{localStorage.getItem(FLAGS_KEY) && (
								<Button
									variant="contained"
									color="primary"
									startIcon={<Refresh />}
									onClick={() => {
										localStorage.removeItem(FLAGS_KEY);
										window.location.reload();
									}}
									sx={{
										ml: 2,
									}}
								>
									Clear Feature Flags and Reload
								</Button>
							)}
						</>
					}
				/>
			);
		}
		const {children} = this.props;
		return children;
	}
}
