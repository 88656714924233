import type {CreateOrganization} from '../interfaces/CreateOrganization';
import type {Organization} from '../interfaces/Organization';
import type {APIOpts} from '../types/APIOpts';
import type {FilterParams} from '../types/FilterParams';
import {naturalCmp} from '../utils/naturalSort';
import {api, TagTypes} from './api';

const API_URL = '/api/organization/v1/organization';

export const organizationsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		createOrganization: builder.mutation<Organization, CreateOrganization>({
			query: (data) => ({
				url: `${API_URL}`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: [{type: TagTypes.Organizations, id: 'LIST'}],
		}),
		getOrganizations: builder.query<
			Organization[],
			{params?: FilterParams; opts?: APIOpts}
		>({
			query: ({params, opts}) => ({
				url: API_URL,
				method: 'GET',
				params,
				opts,
			}),
			transformResponse: (res: {organizations: Organization[]}) =>
				res.organizations.sort((a, b) =>
					naturalCmp(
						a.name.toLocaleLowerCase(),
						b.name.toLocaleLowerCase(),
					),
				),
			providesTags: (result = []) => [
				...result.map(
					({id}) => ({type: TagTypes.Organizations, id}) as const,
				),
				{type: TagTypes.Organizations, id: 'LIST'},
			],
		}),
		getOrganizationById: builder.query<Organization, string>({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: (_result, _err, id) => [
				{type: TagTypes.Organizations, id},
			],
		}),
		updateOrganization: builder.mutation<
			Organization,
			{id: string; data: CreateOrganization}
		>({
			query: ({id, data}) => ({
				url: `${API_URL}/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (_res, _err, req) => [
				{type: TagTypes.Organizations, id: req.id},
				{type: TagTypes.Organizations, id: 'LIST'},
			],
		}),
		moveOrganization: builder.mutation<
			void,
			{id: string; newParentId: string}
		>({
			query: ({id, newParentId}) => ({
				url: `${API_URL}/${id}/move`,
				method: 'POST',
				body: {newParentId},
			}),
			invalidatesTags: (_res, _err, req) => [
				{type: TagTypes.Organizations, id: req.id},
				{type: TagTypes.Organizations, id: req.newParentId},
				{type: TagTypes.Organizations, id: 'LIST'},
			],
		}),
		deleteOrganization: builder.mutation<void, string>({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (_res, _err, id) => [
				{type: TagTypes.Organizations, id},
				{type: TagTypes.Organizations, id: 'LIST'},
			],
		}),
		deleteOrganizations: builder.mutation<void, string[]>({
			queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const id of ids) {
					// eslint-disable-next-line no-await-in-loop
					const res = await baseQuery({
						url: `${API_URL}/${id}`,
						method: 'DELETE',
					});
					if ('error' in res) {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						return res as any;
					}
				}
				return {data: {}};
			},
			invalidatesTags: (_req, _err, ids) => [
				...ids.map(
					(id) => ({type: TagTypes.Organizations, id}) as const,
				),
				{type: TagTypes.Organizations, id: 'LIST'},
			],
		}),
	}),
});

export const {
	useCreateOrganizationMutation,
	useGetOrganizationByIdQuery,
	useGetOrganizationsQuery,
	useUpdateOrganizationMutation,
	useDeleteOrganizationMutation,
	useDeleteOrganizationsMutation,
	useMoveOrganizationMutation,
} = organizationsApi;
