import React, {
	type FormEvent,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	Typography,
	Card,
	CardContent,
} from '@mui/material';
import Alert, {AlertColor} from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import {Link as ReactLink, Navigate} from 'react-router-dom';

import {PublicPageLayout} from '../../components/Layout/PublicPageLayout';
import {useAuth} from '../../hooks/useAuth';
import {useLoginMutation} from '../../services/login';
import {
	getNextRouteAfterLogin,
	popNextRouteAfterLogin,
} from '../../utils/login.session';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		height: '48px',
		marginTop: theme.spacing(1),
	},
}));

type LoginAlert = {severity: AlertColor; text: string};

const getSSOError = (): LoginAlert | null => {
	const params = new URL(document.location.href).searchParams;
	if (params.get('sso') === 'failed') {
		return {
			severity: 'error',
			text: 'Single sign-on failed. Try again',
		};
	}
	return null;
};

export const Login: React.FC = () => {
	const classes = useStyles();
	const {user} = useAuth();
	const [username, setUsername] = useState('');
	const [passwd, setPasswd] = useState('');
	const [remember, setRemember] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [loginAlert, setLoginAlert] = useState<LoginAlert | null>(null);
	const passwordRef = useRef<HTMLInputElement>();
	const [login, {isLoading, error, isUninitialized: passwordNotAttempted}] =
		useLoginMutation();
	const valid = useMemo(
		() => username.indexOf('@') >= 0 && (!showPassword || !!passwd),
		[username, showPassword, passwd],
	);

	const location = useMemo(() => {
		const path = getNextRouteAfterLogin('/');
		return `${window.location.origin}${path}`;
	}, []);

	useEffect(() => {
		if (!passwordNotAttempted) {
			setShowPassword(true);
		}
	}, [passwordNotAttempted, setShowPassword]);

	useEffect(() => {
		if (passwordRef.current) {
			passwordRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordRef.current]);

	useEffect(() => {
		const isWrong = !!error && 'status' in error && error.status === 401;
		if (passwd && isWrong) {
			setLoginAlert({
				severity: 'warning',
				text: 'Wrong username or password',
			});
		} else if (!passwd && isWrong) {
			setLoginAlert(null);
		} else if (error) {
			setLoginAlert({
				severity: 'error',
				text: 'Failed to log in. Try again',
			});
		} else if (!passwd) {
			setLoginAlert(getSSOError());
		} else {
			setLoginAlert(null);
		}
		// NOTE(vfr): hairy, but depends on not re-evaling when password changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	if (user) {
		const to = popNextRouteAfterLogin('/');
		return <Navigate to={to} replace />;
	}

	const handleLogin = (ev: FormEvent<unknown>) => {
		ev.preventDefault();
		if (valid && !isLoading) {
			login({
				email: username,
				password: passwd,
				remember,
				location,
				device: navigator.userAgent,
			});
		}
	};

	return (
		<PublicPageLayout>
			<Card>
				<CardContent>
					<Typography component="h1" variant="h5">
						Log in
					</Typography>
					<form
						className={classes.form}
						noValidate
						onSubmit={handleLogin}
					>
						{!!loginAlert && (
							<Alert
								elevation={6}
								variant="filled"
								severity={loginAlert.severity}
							>
								{loginAlert.text}
							</Alert>
						)}
						<TextField
							variant="outlined"
							margin="normal"
							onChange={(e) => {
								setUsername(e.target.value);
								setLoginAlert(null);
							}}
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus={!showPassword}
							disabled={isLoading}
						/>
						{showPassword && (
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								inputRef={passwordRef}
								onChange={(e) => {
									setPasswd(e.target.value);
									setLoginAlert(null);
								}}
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								disabled={isLoading}
							/>
						)}
						<FormControlLabel
							control={
								<Checkbox
									value="remember"
									checked={remember}
									onChange={(_, c) => setRemember(c)}
									color="secondary"
									disabled={isLoading}
								/>
							}
							label="Remember me"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={isLoading || !valid}
						>
							{isLoading ? 'Checking...' : 'Log in'}
						</Button>
						{showPassword && (
							<Button
								type="link"
								fullWidth
								variant="contained"
								color="secondary"
								component={ReactLink}
								to="/u/recover"
								className={classes.submit}
							>
								Forgot password
							</Button>
						)}
					</form>
				</CardContent>
			</Card>
		</PublicPageLayout>
	);
};
