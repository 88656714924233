import React, {FC} from 'react';

import {
	Box,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Feature as FlaggedFeature} from 'flagged';
import {Link} from 'react-router-dom';

import {Feature} from '../../enums/Feature';
import {useMediaExtended} from '../../hooks/useMediaExtended';
import {DARK_BLUE} from '../../theme/colors';
import {menuItemSize} from './common';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 4,
		padding: 0,
		margin: '0 auto 16px',
		'&$selected': {
			backgroundColor: 'transparent',
			position: 'relative',
			'& $selectedMarker': {
				display: 'flex',
			},
		},
		'&$selected:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover': {
			backgroundColor: 'transparent',
			'& $selectedMarker': {
				display: 'flex',
			},
		},
	},
	icon: {
		color: theme.palette.secondary.main,
		fontWeight: 500,
		fontSize: 18,
		width: menuItemSize,
		minWidth: 0,
		justifyContent: 'center',
		alignItems: 'center',
	},
	gutter: {
		paddingLeft: 8,
		paddingRight: 8,
	},
	selected: {
		backgroundColor: 'transparent',
	},
	selectedMarker: {
		content: '',
		display: 'none',
		position: 'absolute',
		height: 2,
		backgroundColor: DARK_BLUE,
		bottom: 0,
		left: '50%',
		borderRadius: '2px',
		transform: 'translateX(-50%)',
	},
}));

interface MenuItemProps {
	pathname: string;
	to: string;
	icon: React.ReactNode;
	title: string;
	prefix?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	feature?: Feature;
}

const MenuItemInner: FC<MenuItemProps> = ({
	pathname,
	to,
	icon,
	prefix,
	title,
}) => {
	const selected = prefix ? pathname.startsWith(to) : pathname === to;
	const classes = useStyles();
	const isMenuMobile = useMediaExtended('md');

	return (
		<ListItem
			disablePadding
			component={Link}
			to={to}
			selected={selected}
			classes={{
				root: classes.root,
				selected: classes.selected,
			}}
		>
			<ListItemButton sx={{paddingLeft: {md: 0}, paddingRight: {md: 0}}}>
				{isMenuMobile ? (
					<Tooltip title={title} arrow placement="right">
						<ListItemIcon classes={{root: classes.icon}}>
							{icon}
						</ListItemIcon>
					</Tooltip>
				) : (
					<>
						<ListItemIcon classes={{root: classes.icon}}>
							{icon}
						</ListItemIcon>
						<ListItemText>{title}</ListItemText>
					</>
				)}
				<Box
					className={classes.selectedMarker}
					sx={{
						width: {md: 8, xs: '80%'},
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
};
const MenuItem: FC<MenuItemProps> = (props) => {
	const {feature} = props;
	if (feature) {
		return (
			<FlaggedFeature name={feature}>
				<MenuItemInner {...props} />
			</FlaggedFeature>
		);
	}
	return <MenuItemInner {...props} />;
};

export default MenuItem;
