import React from 'react';

import {Button, Typography} from '@mui/material';

import {Refresh} from '../Icon';
import {EmptyState} from './EmptyState';

const ReloadError: React.FC<{
	title: string;
	description: string;
	reload: () => void;
}> = ({title, description, reload}) => (
	<EmptyState
		image={
			<Typography
				variant="h1"
				component="h1"
				style={{
					display: 'flex',
					alignContent: 'center',
					textAlign: 'center',
				}}
			>
				500
			</Typography>
		}
		title={title}
		description={description}
		button={
			<Button
				variant="contained"
				color="primary"
				startIcon={<Refresh />}
				onClick={reload}
			>
				Reload
			</Button>
		}
	/>
);
export default ReloadError;
