import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import type {ForgotPasswordRecoverRequest} from '../interfaces/ForgotPasswordRecoverRequest';
import type {LoginRequest} from '../interfaces/LoginRequest';
import type {LoginResponse} from '../interfaces/LoginResponse';
import {API_SERVER} from '../utils/apiServer';

const baseUrl = API_SERVER.toString();

const tag = 'LoggedInUser';

export const loginApi = createApi({
	reducerPath: 'login',
	tagTypes: [tag],
	baseQuery: fetchBaseQuery({
		baseUrl,
		credentials: 'include',
		mode: baseUrl !== window.location.origin ? 'cors' : undefined,
	}),
	endpoints: (builder) => ({
		getLoggedInUser: builder.query<LoginResponse, void>({
			query: () => ({
				url: '/api/auth/v1/token',
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					device: navigator.userAgent,
				},
			}),
			providesTags: [{type: tag, id: 'USER'}],
		}),
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: (credentials) => ({
				url: '/api/auth/v1/login',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: credentials,
			}),
			// invalidatesTags: [{type: tag, id: 'USER'}],
		}),
		logout: builder.mutation<void, void>({
			query: () => ({
				url: '/api/auth/v1/token',
				method: 'DELETE',
			}),
			invalidatesTags: [{type: tag, id: 'USER'}],
		}),
		forgotPasswordInit: builder.mutation<void, string>({
			query: (email) => ({
				url: '/api/auth/v1/forgot/init',
				method: 'POST',
				body: {email},
			}),
		}),
		forgotPasswordRecover: builder.mutation<
			void,
			ForgotPasswordRecoverRequest
		>({
			query: (data) => ({
				url: '/api/auth/v1/forgot/reset',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: [{type: tag, id: 'USER'}],
		}),
	}),
});

export const {
	useGetLoggedInUserQuery,
	useLogoutMutation,
	useLoginMutation,
	useForgotPasswordInitMutation,
	useForgotPasswordRecoverMutation,
} = loginApi;
