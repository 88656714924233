import React from 'react';

import {
	Button,
	Card,
	CardContent,
	Checkbox,
	Typography,
	FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useFormik, FormikValues} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';

import BaseInput from '../../components/Forms/BaseInput';
import {DetailedFetchAPIError} from '../../components/Forms/FormAPIError';
import {PublicPageLayout} from '../../components/Layout/PublicPageLayout';
import useMutation from '../../hooks/useMutation';
import {useForgotPasswordRecoverMutation} from '../../services/login';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		height: '48px',
		marginTop: theme.spacing(1),
	},
}));

const validationSchema = yup.object({
	password: yup
		.string()
		.required()
		.min(9, 'A minimum of 9 characteres is required'),
	repeat: yup
		.string()
		.oneOf([yup.ref('password'), ''], 'Passwords must match'),
	remember: yup.boolean().required(),
});

const initialValues = {
	password: '',
	repeat: '',
	remember: true,
};

export const WelcomeSetPassword: React.FC = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const {id = ''} = useParams<{id: string}>();
	const [forgotPasswordRecover, forgotPasswordRecoverMutationStatus] =
		useForgotPasswordRecoverMutation();
	const forgotStatus = useMutation(
		forgotPasswordRecoverMutationStatus,
		'Failed to create password',
		'Password changed!',
		() => navigate('/'),
	);
	const {isLoading} = forgotPasswordRecoverMutationStatus;
	const formik = useFormik<FormikValues>({
		validationSchema,
		validateOnMount: true,
		initialValues,
		onSubmit: (values) => {
			if (formik.isValid && !isLoading) {
				const {password, repeat, remember} = values;
				const req = {
					id,
					password,
					repeat,
					remember,
					device: navigator.userAgent,
				};
				forgotPasswordRecover(req);
			}
		},
	});

	return (
		<PublicPageLayout>
			<Card>
				<CardContent>
					<Typography component="h1" variant="h5">
						Welcome
					</Typography>
					<Typography
						component="p"
						variant="subtitle1"
						color="textSecondary"
					>
						Set your password
					</Typography>
					<form
						className={classes.form}
						onSubmit={formik.handleSubmit}
					>
						<BaseInput
							name="password"
							label="New Password"
							formik={formik}
							type="password"
							autoComplete="new-password"
							autoFocus
							required
							disabled={isLoading}
						/>
						<BaseInput
							name="repeat"
							type="password"
							autoComplete="new-password"
							label="Repeat new Password"
							formik={formik}
							required
							disabled={isLoading}
						/>
						<FormControlLabel
							control={
								<Checkbox
									value="remember"
									checked={formik.values.remember}
									onChange={(_, c) =>
										formik.setFieldValue('remember', c)
									}
									color="secondary"
									disabled={isLoading}
								/>
							}
							label="Remember me"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={isLoading || !formik.isValid}
							className={classes.submit}
						>
							Log in
						</Button>
					</form>
					<DetailedFetchAPIError {...forgotStatus} />
				</CardContent>
			</Card>
		</PublicPageLayout>
	);
};
