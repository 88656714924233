import React from 'react';

import {AlertTitle, Button, Fade} from '@mui/material';
import Alert from '@mui/material/Alert';

import type APIError from '../../types/APIError';

// TODO: Different alert levels, maybe we can be more helpful (i.e., not connected to internet)
// TODO: Better details (traceID, validation error, copy for sending to support etc?)

const getErrorStatusCode = (error: APIError): number => {
	if ('status' in error && typeof error.status === 'number') {
		return error.status;
	}
	if ('originalStatus' in error && typeof error.originalStatus === 'number') {
		return error.originalStatus;
	}
	return 500;
};

const getErrorStringFromStatusCode = (error: APIError) => {
	const code = getErrorStatusCode(error);
	switch (code) {
		case 409:
			return 'One of the input fields are unique, and it already exists in our system.';
		case 404:
			return 'The requested resource was not found.';
		case 403:
			return 'You are not allowed to modify this resource.';
		case 401:
			return 'You are not authenticated, please log in.';
		case 400:
			return 'The server failed to validate some of your inputs. Please review';
		default:
			return `The server failed to process you request. Please try again (status=${code})`;
	}
};

export const DetailedFetchAPIError: React.FC<{
	title?: string | undefined;
	error?: APIError | undefined;
	refetch?: (() => void) | undefined;
	reset?: (() => void) | undefined;
}> = ({title, error, refetch, reset}) => {
	if (!error) {
		return null;
	}

	let action;
	if (refetch) {
		action = (
			<Button
				color="inherit"
				size="small"
				type="button"
				aria-label="Retry"
				onClick={refetch}
			>
				RETRY
			</Button>
		);
	}

	return (
		<Fade in>
			<Alert
				variant="filled"
				severity="error"
				onClose={reset}
				action={action}
				sx={{marginTop: 1, marginBottom: 1}}
			>
				{title && <AlertTitle>{title}</AlertTitle>}
				{getErrorStringFromStatusCode(error)}
			</Alert>
		</Fade>
	);
};

const FormAPIError: React.FC<{error?: APIError}> = ({error}) => {
	if (!error) {
		return null;
	}
	return (
		<Alert
			elevation={6}
			variant="filled"
			severity="error"
			sx={{marginTop: 1}}
		>
			{getErrorStringFromStatusCode(error)}
		</Alert>
	);
};

export default FormAPIError;
