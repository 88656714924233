import {useCallback, useState} from 'react';

export type UsedModal = [boolean, (state: boolean) => void];

const useModal: () => UsedModal = () => {
	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const toggleModal = useCallback((state: boolean) => {
		setModalVisible(state);
	}, []);

	return [modalVisible, toggleModal];
};

export default useModal;
