import React, {FC} from 'react';

import {Backdrop} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import PlatformPng from '../../images/background.webp';
import {DARK_GREY} from '../../theme/colors';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: DARK_GREY,
	},
	image: {
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${PlatformPng})`,
		position: 'absolute',
		top: 0,
		width: '100vw',
		height: '1052px',
	},
}));

export const WavesBackground: FC = () => {
	const classes = useStyles();
	return (
		<Backdrop
			data-testid="waves-bg"
			open
			timeout={0}
			classes={{
				root: classes.root,
			}}
		>
			<div className={classes.image} />
		</Backdrop>
	);
};
