import React from 'react';

import {StyledEngineProvider, Theme} from '@mui/material/styles';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';

import {App} from './App';
import {store} from './store/store';

// Needed to work around dependency/import order issues with fullcalendar/vite
import '@fullcalendar/react/dist/vdom';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const root = createRoot(document.getElementById('root')!);

function start() {
	root.render(
		<Provider store={store}>
			<StyledEngineProvider injectFirst>
				<App />
			</StyledEngineProvider>
		</Provider>,
	);
}

if (import.meta.env.VITE_USE_MSW === 'true') {
	import('./mocks/browser').then(({worker}) => {
		worker
			.start({
				onUnhandledRequest:
					import.meta.env.VITE_MSW_ERROR_ON_UNHANDLED_REQUEST ===
					'true'
						? 'error'
						: 'warn',
			})
			.then(start);
	});
} else {
	start();
}
