import React, {FC, useMemo} from 'react';

import {useFeatures} from 'flagged';
import {Navigate, PathRouteProps, useLocation} from 'react-router-dom';

import {useAuth} from '../hooks/useAuth';
import {setNextRouteAfterLogin} from '../utils/login.session';

interface PrivateRouteProps extends PathRouteProps {
	feature?: string; // redirect to '/' if feature is not provided
}

export const PrivateRoute: FC<PrivateRouteProps> = ({children, feature}) => {
	const location = useLocation();
	const {user} = useAuth();
	const features = useFeatures();
	const redirect = useMemo(() => {
		if (!user) {
			setNextRouteAfterLogin(window.location.pathname);
			return '/login';
		}
		if (!feature) {
			return null;
		}
		const hasFeature = !!features[feature];
		if (!hasFeature) {
			return '/';
		}
		return null;
	}, [user, feature, features]);
	if (redirect) {
		return <Navigate to={redirect} replace state={{from: location}} />;
	}
	return <>{children}</>;
};
