import {getOverlayAlpha} from '@mui/material';
import createStyles from '@mui/styles/createStyles';

import {DARK_BLUE} from './colors';

export const mixins = createStyles({
	ellipsis: {
		display: 'inline-block',
		width: '100%',
		maxWidth: '100%',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	btnAccent: {
		border: `2px solid ${DARK_BLUE}`,
		color: DARK_BLUE,
	},
	shadowOnHover: {
		cursor: 'pointer',
		boxShadow: 'none',
		'&:hover': {
			boxShadow:
				'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
		},
	},
	backgroundGradient: {
		backgroundImage: `linear-gradient(rgba(255, 255, 255, ${getOverlayAlpha(
			1,
		)}), rgba(255, 255, 255, ${getOverlayAlpha(1)}))`,
	},
});
