import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
}));

const Spacer = () => {
	const styles = useStyles();
	return <div className={styles.root} />;
};

export default Spacer;
