export const POLLING_INTERVAL_DEFAULT = 30 * 1000;
export const REFRESH_STATUS_INTERVAL = 1000;
export const DATE_INPUT_FORMAT = 'dd.MM.yyyy';
export const SHORT_DATE_INPUT_FORMAT = 'dd.MM.yy';
export const TIME_FORMAT = 'HH.mm';
export const TIME_FORMAT_2 = 'HH:mm';
export const DATE_TIME_FORMAT = 'HH:mm MMMM dd, yyyy';
export const DATE_FORMAT = 'MMMM dd, yyyy';

export const NEOWIT_ORG_ID = 'x7uyEkHByL9iB9R4R2YTYS';

export const DECK_ICON_SIZE = 40;
export const WORKSPACES_DECK_ICON_SIZE = 30;
export const CLUSTER_ICON_SIZE = 50;
