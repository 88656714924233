import type {User} from '../interfaces/User';
import log from '../services/logging';

export const setSessionStorageOrgId = (user: User, orgId: string) => {
	try {
		log.info(
			`sessionStorage:set selected org user=${user.id} org=${orgId}`,
		);
		window.sessionStorage.setItem(`org:${user.id}`, orgId);
	} catch (e) {
		log.error('sessionStorage:set Failed to set', e);
	}
};

export const removeSessionStorageOrgId = (user: User) => {
	try {
		log.info(`sessionStorage:remove selected org user=${user.id}`);
		window.sessionStorage.removeItem(`org:${user.id}`);
	} catch (e) {
		log.error('sessionStorage:set Failed to set', e);
	}
};

const getSessionStorageOrgId = (user: User): string | null => {
	try {
		log.info(`sessionStorage:get fetching org user=${user.id}`);
		return window.sessionStorage.getItem(`org:${user.id}`);
	} catch (e) {
		log.error('sessionStorage:get Failed to get', e);
		return null;
	}
};

export const getSessionStorageOrgIdOrDefault = (user: User): string => {
	const selected = getSessionStorageOrgId(user);
	return selected || user.parent;
};
