import React, {FC, useMemo, useState} from 'react';

import LayoutContext, {defaultValue} from './LayoutContext';

const LayoutProvider: FC = ({children}) => {
	const [scrollTopPosition, setScrollTopPosition] = useState<boolean>(
		defaultValue.scrollTopPosition,
	);
	const value = useMemo(
		() => ({
			scrollTopPosition,
			setScrollTop: (scrollTop: number) =>
				setScrollTopPosition(!!scrollTop),
		}),
		[scrollTopPosition],
	);
	return (
		<LayoutContext.Provider value={value}>
			{children}
		</LayoutContext.Provider>
	);
};

export default LayoutProvider;
