import React, {FC} from 'react';

import {TextField} from '@mui/material';
import {FormikProps, FormikValues} from 'formik';

const BaseInput: FC<{
	name: string;
	label: string;
	formik: FormikProps<FormikValues>;
	[x: string]: unknown;
}> = ({name, label, formik, ...rest}) => (
	<TextField
		variant="outlined"
		margin="normal"
		fullWidth
		name={name}
		label={label}
		InputLabelProps={{shrink: true}}
		value={formik.values[name]}
		onChange={formik.handleChange}
		onBlur={formik.handleBlur}
		error={formik.touched[name] && Boolean(formik.errors[name])}
		helperText={formik.touched[name] && formik.errors[name]}
		{...rest}
	/>
);

export default BaseInput;
