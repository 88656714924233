import React, {FC, useMemo, useState} from 'react';

import {Close, Search} from '@mui/icons-material';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {debounce} from 'lodash-es';

import {DARK_GREY, LIGHT_GREY, LIGHT_GREY_2, WHITE} from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.mode === 'light' ? DARK_GREY : WHITE,
		backgroundColor: theme.palette.mode === 'light' ? WHITE : DARK_GREY,
	},
	input: {
		paddingTop: 0,
		paddingBottom: 0,
		height: 40,
		borderRadius: 12,
		'&::-webkit-input-placeholder': {
			color: theme.palette.mode === 'light' ? DARK_GREY : WHITE,
		},
	},
	notchedOutline: {
		borderWidth: 2,
		borderColor: theme.palette.mode === 'light' ? LIGHT_GREY : LIGHT_GREY_2,
		borderRadius: 12,
		top: -4, // this change required for top border
	},
}));

interface FilterInputProps {
	onChange: (value: string) => void;
	id?: string;
	label?: string;
	value?: string;
	autoFocus?: boolean;
}

const FilterInput: FC<FilterInputProps> = ({
	onChange,
	id = 'filter-input',
	label = 'Filter',
	value = '',
	autoFocus,
}) => {
	const classes = useStyles();
	const [query, setQuery] = useState(value);
	const debouncedChangeHandler = useMemo(() => {
		const changeHandler: React.ChangeEventHandler<
			HTMLTextAreaElement | HTMLInputElement
		> = (event) => {
			onChange(event.target.value);
		};
		return debounce(changeHandler, 300);
	}, [onChange]);

	return (
		<FormControl
			variant="outlined"
			hiddenLabel
			sx={{
				maxWidth: {sm: 250},
				width: '100%',
			}}
		>
			<OutlinedInput
				id={id}
				classes={{
					root: classes.root,
					input: classes.input,
					notchedOutline: classes.notchedOutline,
				}}
				value={query}
				onChange={(event) => {
					setQuery(event.target.value);
					debouncedChangeHandler(event);
				}}
				autoComplete="off"
				placeholder={label}
				startAdornment={
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				}
				autoFocus={autoFocus}
				endAdornment={
					value?.length ? (
						<InputAdornment position="end">
							<IconButton
								onClick={() => {
									if (query?.length) {
										setQuery('');
										onChange('');
									}
								}}
								edge="end"
								disableRipple
							>
								<Close
									sx={{width: '0.75em', height: '0.75em'}}
								/>
							</IconButton>
						</InputAdornment>
					) : undefined
				}
			/>
		</FormControl>
	);
};

export default FilterInput;
