import React from 'react';

import {AutoMode, DarkMode, LightMode} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';

import {setTheme, themeSelector} from '../../services/themeSlice';
import MenuButton from './MenuButton';

function nextTheme(theme: 'light' | 'dark' | 'auto') {
	switch (theme) {
		case 'light':
			return 'dark';
		case 'dark':
			return 'auto';
		case 'auto':
			return 'light';
		default:
			return 'light';
	}
}

const MODES = {
	light: {icon: <LightMode />, title: 'Light mode'},
	dark: {icon: <DarkMode />, title: 'Dark mode'},
	auto: {icon: <AutoMode />, title: 'Auto mode'},
};

const SelectThemeButton = () => {
	const themeMode = useSelector(themeSelector);
	const dispatch = useDispatch();
	const handleThemeSelector = () => {
		dispatch(setTheme(nextTheme(themeMode)));
	};
	return (
		<MenuButton
			icon={MODES[themeMode].icon}
			onClick={handleThemeSelector}
			title={MODES[themeMode].title}
		/>
	);
};

export default SelectThemeButton;
