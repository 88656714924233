import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {MEDIUM_GREY} from '../../theme/colors';
import {mixins} from '../../theme/mixins';

const dialogBorderPadding = 32;
const dialogBorderPaddingXs = 12;

export const DEFAULT_DIALOG_WIDTH = 500;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			...mixins.ellipsis,
			[theme.breakpoints.down('md')]: {
				paddingTop: dialogBorderPaddingXs,
				paddingBottom: dialogBorderPaddingXs,
				paddingLeft: dialogBorderPaddingXs,
				paddingRight: dialogBorderPaddingXs,
			},
		},
		sectionTitle: {
			fontSize: 14,
			lineHeight: '16px',
			letterSpacing: 0.3,
			color: MEDIUM_GREY,
			marginTop: 0,
			display: 'flex',
			alignItems: 'center',
			'&>svg': {
				marginRight: 8,
			},
			[theme.breakpoints.down('md')]: {
				paddingLeft: dialogBorderPaddingXs,
			},
		},
		dialogContainer: {
			borderRadius: 16,
			boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
		},
		dialogContainerRight: {
			position: 'absolute',
			top: 0,
			right: 0,
			maxWidth: DEFAULT_DIALOG_WIDTH,
			borderBottomRightRadius: 0,
			borderTopRightRadius: 0,
			borderRadius: 8,
		},
		dialogTitle: {
			lineHeight: 'normal',
			paddingTop: dialogBorderPadding,
			paddingLeft: dialogBorderPadding,
			paddingRight: dialogBorderPadding,
			[theme.breakpoints.down('md')]: {
				paddingTop: dialogBorderPaddingXs,
				paddingLeft: dialogBorderPaddingXs,
				paddingRight: dialogBorderPaddingXs,
			},
		},
		dialogContent: {
			paddingLeft: dialogBorderPadding,
			paddingRight: dialogBorderPadding,
			[theme.breakpoints.down('md')]: {
				paddingLeft: dialogBorderPaddingXs,
				paddingRight: dialogBorderPaddingXs,
			},
		},
		dialogActions: {
			justifyContent: 'space-between',
			paddingLeft: dialogBorderPadding,
			paddingRight: dialogBorderPadding,
			paddingBottom: dialogBorderPadding,
			[theme.breakpoints.down('md')]: {
				paddingLeft: dialogBorderPaddingXs,
				paddingRight: dialogBorderPaddingXs,
				paddingBottom: dialogBorderPaddingXs,
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		alwaysScroll: {
			overflowY: 'scroll',
			'&::-webkit-scrollbar': {
				width: '7px',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0,0,0,.5)',
				'-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
				borderRadius: 4,
			},
		},
	}),
);

export default useStyles;
