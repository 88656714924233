import React from 'react';

import {Backdrop, CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export const FullscreenLoader: React.FC = () => {
	const classes = useStyles();
	return (
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
