import {useMemo} from 'react';

import {useMediaQuery} from '@mui/material';
import {alpha, createTheme, darken} from '@mui/material/styles';
import {useSelector} from 'react-redux';

import {themeSelector} from '../services/themeSlice';
import {
	BLUE,
	DARK_BLUE,
	DARK_BLUE_PLUS,
	DARK_GREY,
	LIGHT_GREEN,
	LIGHT_GREY,
	LIGHT_GREY_2,
	PINKISH_RED,
	ROUGE,
	ROUGE_2,
	VIOLET,
	WHITE,
} from './colors';

export const DEFAULT_BORDER_RADIUS = '12px';

function getPreference(
	preference: 'light' | 'dark' | 'auto',
	prefersDarkMode: boolean,
) {
	if (preference === 'auto') {
		return prefersDarkMode ? 'dark' : 'light';
	}
	return preference;
}

export const createThemeSelected = (mode: 'dark' | 'light') => {
	const paletteColorError = mode === 'light' ? ROUGE : ROUGE_2;
	return createTheme({
		palette: {
			mode,
			primary: {
				main: DARK_BLUE,
				dark: DARK_BLUE_PLUS,
				light: BLUE,
			},
			secondary: {
				main: mode === 'light' ? DARK_GREY : LIGHT_GREY,
				dark: mode === 'light' ? DARK_BLUE_PLUS : LIGHT_GREY,
				light: BLUE,
			},
			error: {
				main: paletteColorError,
				light: PINKISH_RED,
				dark: darken(ROUGE, 0.4),
			},
			background: {
				default: mode === 'light' ? '#f9f9f9' : DARK_GREY,
				paper: mode === 'light' ? WHITE : DARK_GREY,
			},
			success: {
				main: LIGHT_GREEN,
				dark: darken(LIGHT_GREEN, 0.4),
				light: LIGHT_GREEN,
			},
		},
		components: {
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: DEFAULT_BORDER_RADIUS,
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontWeight: 400,
					},
				},
			},
			MuiButtonGroup: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						borderRadius: DEFAULT_BORDER_RADIUS,
					},
					groupedContainedHorizontal: {
						'&:not(:last-child)': {
							borderRight: undefined,
						},
					},
					contained: {
						boxShadow: undefined,
						backgroundColor: LIGHT_GREY,
					},
					grouped: {
						color: mode === 'light' ? DARK_GREY : WHITE,
						backgroundColor: mode === 'light' ? WHITE : DARK_GREY,
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					colorPrimary: {
						color: DARK_BLUE,
						backgroundColor: `${VIOLET} !important`,
						'&:hover': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
						'&:focus': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
						'&:disabled': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
					},
				},
			},
			MuiButton: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						minHeight: 40,
						fontSize: 14,
						lineHeight: '14px',
						fontStyle: 'normal',
						fontWeight: 'bold',
						letterSpacing: '0.1px',
						textTransform: 'none',
						borderRadius: DEFAULT_BORDER_RADIUS,
					},
					contained: {
						flex: 'none',
						flexGrow: 0,
						boxShadow: undefined,
					},
					containedPrimary: {
						color: BLUE,
						backgroundColor: VIOLET,
						'&:hover': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
						'&:disabled': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
					},
					containedSecondary: {
						color: mode === 'light' ? DARK_GREY : LIGHT_GREY,
						border: `2px solid ${LIGHT_GREY}`,
						backgroundColor:
							mode === 'light' ? '#ffffff' : DARK_GREY,
						'&:hover': {
							backgroundColor:
								mode === 'light' ? '#ffffff' : DARK_GREY,
							color: mode === 'light' ? DARK_GREY : LIGHT_GREY,
						},
						'&:disabled': {
							backgroundColor: alpha(LIGHT_GREY, 0.6),
							color: DARK_GREY,
						},
					},
					outlinedSecondary: {
						color: mode === 'light' ? VIOLET : LIGHT_GREY,
						border: `2px solid ${LIGHT_GREY}`,
						'&:hover': {
							border: `2px solid ${LIGHT_GREY}`,
						},
					},
					outlinedError: {
						color: paletteColorError,
						border: `2px solid ${paletteColorError}`,
						'&:hover': {
							color: paletteColorError,
							border: `2px solid ${paletteColorError}`,
						},
					},
					textPrimary: {
						height: '18px',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: '14px',
						lineHeight: '21px',
						textAlign: 'center',
						letterSpacing: '0.1px',
					},
					textSecondary: {
						height: '18px',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: '14px',
						lineHeight: '21px',
						textAlign: 'left',
						letterSpacing: '0.1px',
					},
				},
			},
			MuiFab: {
				styleOverrides: {
					primary: {
						color: BLUE,
						backgroundColor: VIOLET,
						'&:hover': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
						'&:disabled': {
							backgroundColor: VIOLET,
							color: LIGHT_GREY,
						},
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: 'none',
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					indicator: {
						backgroundColor: DARK_BLUE,
						height: 4,
					},
				},
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						backgroundColor: '#e5e5e5',
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					colorSecondary: {
						'&.Mui-checked': {
							color: DARK_BLUE,
						},
					},
					track: {
						'.Mui-checked.Mui-checked + &': {
							backgroundColor: LIGHT_GREY,
						},
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						borderColor: LIGHT_GREY,
						borderWidth: 2,
						borderRadius: '12px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderColor: LIGHT_GREY,
						borderWidth: 2,
						borderRadius: '12px',
					},
					notchedOutline: {
						borderColor: LIGHT_GREY_2,
						borderWidth: 2,
						borderRadius: '12px',
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: '12px',
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						borderRadius: DEFAULT_BORDER_RADIUS,
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						textDecoration: 'none',
						color: 'inherit',
					},
				},
			},
		},
		typography: {
			h1: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '24px',
				lineHeight: '28px',
			},
			h2: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '22px',
				lineHeight: '26px',
			},
			h3: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '22px',
				lineHeight: '26px',
			},
			body1: {
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '14px',
				lineHeight: '16px',
			},
			body2: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '14px',
				lineHeight: '16px',
			},
		},
		transitions: {
			duration: {
				standard: 150,
				complex: 200,
			},
		},
		shape: {
			borderRadius: 12,
		},
	});
};
export const useThemeSelected = () => {
	const preference = useSelector(themeSelector);
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const mode = getPreference(preference, prefersDarkMode);

	return useMemo(() => createThemeSelected(mode), [mode]);
};
