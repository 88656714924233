import React, {ComponentType} from 'react';

import loadable from '@loadable/component';
import {nanoid} from 'nanoid';
import {Navigate, useParams} from 'react-router-dom';

import {Feature} from '../enums/Feature';
import {Login} from '../views/userlogin/Login';
import {Logout} from '../views/userlogin/Logout';
import {WelcomeSetPassword} from '../views/userrecover/WelcomeSetPassword';

const Location = loadable(() => import('../views/location'));
const OAuth2 = loadable(() => import('../views/oauth2'));
const Devices = loadable(() => import('../views/devices'));
const Dashboard = loadable(() => import('../views/dashboard'));
const Reports = loadable(() => import('../views/report'));
const ReportsDispatcher = loadable(() =>
	import('../views/report').then(({ReportDispatcher}) => ({
		default: ReportDispatcher,
	})),
);
const Settings = loadable(() => import('../views/settings'));
const UserProfile = loadable(() => import('../views/userprofile'));
const UserRecover = loadable(() => import('../views/userrecover'));
const Workspaces = loadable(() => import('../views/workspaces'));

interface BasicRoute {
	path: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: ComponentType<any>;
	isPrivate?: boolean;
	feature?: Feature;
	key: string;
}

const routes: BasicRoute[] = [
	{
		path: 'logout',
		component: Logout,
		key: nanoid(),
	},
	{
		path: 'login',
		component: Login,
		key: nanoid(),
	},
	{
		path: 'oauth2/*',
		component: OAuth2,
		key: nanoid(),
	},
	{
		path: 'locations/*',
		component: Location,
		feature: Feature.Admin,
		isPrivate: true,
		key: nanoid(),
	},
	{
		path: 'devices/*',
		component: Devices,
		isPrivate: true,
		feature: Feature.Admin,
		key: nanoid(),
	},
	{
		path: 'dashboard/*',
		component: Dashboard,
		isPrivate: true,
		feature: Feature.Dashboard,
		key: nanoid(),
	},
	{
		path: 'report/*',
		key: nanoid(),
		feature: Feature.Dashboard,
		// TODO: Add redirect support to RootRoutes instead
		component: () => {
			// eslint-disable-next-line react-hooks/rules-of-hooks -- this is a component, albeit an anonymous one
			const params = useParams<{'*': string}>();
			return React.createElement(Navigate, {
				to: `/insights/${params['*']}`,
			});
		},
	},
	// a bit of a hack to allow non-admins to access to shared reports
	{
		path: 'insights/report/:id',
		component: ReportsDispatcher,
		isPrivate: true,
		key: nanoid(),
	},
	{
		path: 'insights/*',
		component: Reports,
		isPrivate: true,
		feature: Feature.Dashboard,
		key: nanoid(),
	},
	{
		path: 'workspaces/*',
		component: Workspaces,
		isPrivate: true,
		feature: Feature.Workspace,
		key: nanoid(),
	},
	{
		path: 'settings/*',
		component: Settings,
		isPrivate: true,
		feature: Feature.Admin,
		key: nanoid(),
	},
	{
		path: 'u/profile/*',
		component: UserProfile,
		isPrivate: true,
		key: nanoid(),
	},
	{
		path: 'u/recover/*',
		component: UserRecover,
		key: nanoid(),
	},
	{
		path: 'u/welcome/:id',
		component: WelcomeSetPassword,
		key: nanoid(),
	},
];

export default routes;
