import {Id, toast, ToastContent, ToastOptions} from 'react-toastify';

export class NotificationsService {
	static success(
		message: ToastContent<unknown>,
		options?: ToastOptions | undefined,
	) {
		return toast.info(message, options);
	}

	static error(message: string, options?: ToastOptions | undefined) {
		return toast.error(message, options);
	}

	static warning(message: string, options?: ToastOptions | undefined) {
		return toast.warn(message, options);
	}

	static done(toastId: Id) {
		return toast.done(toastId);
	}

	static dismiss(toastId: Id) {
		return toast.dismiss(toastId);
	}
}
