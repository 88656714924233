import React, {FC, useEffect} from 'react';

import {FullscreenLoader} from '../../components/FullscreenLoader/FullscreenLoader';
import {useLogoutMutation} from '../../services/login';

export const Logout: FC = () => {
	const [logout] = useLogoutMutation();
	useEffect(() => {
		logout();
	}, [logout]);
	return <FullscreenLoader />;
};
