import {MouseEvent, useState} from 'react';

const useMenu = (): [
	null | HTMLElement,
	(event: MouseEvent<HTMLButtonElement>) => void,
	() => void,
] => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	return [anchorEl, handleClick, handleClose];
};

export default useMenu;
