import React, {FC, Suspense} from 'react';

import {ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './views/dashboard/css/react-grid.css';
import {ErrorBoundry} from './components/Errors';
import {Layout} from './components/Layout/Layout';
import Loading from './components/Layout/Loading';
import {RootRoutes} from './routes/RootRoutes';
import {useThemeSelected} from './theme/useThemeSelected';

const getAdapterLocale = () => {
	const adapterLocale = enLocale;
	if (adapterLocale.options) {
		adapterLocale.options.weekStartsOn = 1;
	} else {
		adapterLocale.options = {weekStartsOn: 1};
	}
	return adapterLocale;
};

export const App: FC = () => {
	const theme = useThemeSelected();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={getAdapterLocale()}
			>
				<ErrorBoundry>
					<Suspense fallback={<Loading />}>
						<BrowserRouter>
							<Layout>
								<RootRoutes />
							</Layout>
						</BrowserRouter>
					</Suspense>
					<ToastContainer
						theme="dark"
						position="top-right"
						autoClose={5000}
						limit={5}
						hideProgressBar
						closeOnClick
						pauseOnHover
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
					/>
				</ErrorBoundry>
			</LocalizationProvider>
		</ThemeProvider>
	);
};
