import React, {FC, useEffect, useState} from 'react';

import clsx from 'clsx';

import LogoNegative from '../../images/logo.svg?raw';
import LogoPositive from '../../images/neowit-logo-pos.svg?raw';

let counter = 0;
const testId = 'components-Logo';

export const Logo: FC<{
	className?: string;
	variant?: 'positive' | 'negative';
}> = ({className, variant = 'positive'}) => {
	const [html, setHtml] = useState('');
	useEffect(() => {
		counter += 1;
		switch (variant) {
			case 'positive':
				setHtml(LogoPositive.replace('clip0', `clip${counter}`));
				break;
			case 'negative':
				setHtml(LogoNegative.replace('clip0', `clip${counter}`));
				break;
			default:
				break;
		}
	}, [variant]);

	return (
		<div
			data-testid={testId}
			className={clsx(className)}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{__html: html}}
		/>
	);
};
