import React, {FC} from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import clsx, {ClassDictionary} from 'clsx';

import type {BaseModalProps} from '../../interfaces/BaseModalProps';
import useStyles from './BaseModal.styles';

const testId = 'dialog-BaseModal';

const BaseModal: FC<BaseModalProps> = ({
	children,
	toggleModal,
	onResult,
	title = '',
	positive = 'Ok',
	negative = 'Cancel',
	inProgress = false,
	alwaysScroll = false,
	disablePortal = false,
	...rest
}) => {
	const styles = useStyles();
	const handleClose = () => {
		toggleModal(false);
	};

	const handleAcceptClick = () => {
		if (onResult) {
			onResult();
		}
	};

	return (
		<Dialog
			data-testid={testId}
			open
			onClose={handleClose}
			disablePortal={disablePortal}
			{...rest}
			classes={{
				...(rest.classes as ClassDictionary),
				paper: clsx(
					styles.dialogContainer,
					(rest.classes as ClassDictionary)?.paper,
				),
			}}
		>
			<DialogTitle className={styles.dialogTitle}>
				<span className={styles.title}>{title}</span>
			</DialogTitle>
			<DialogContent
				className={clsx(
					styles.dialogContent,
					alwaysScroll && styles.alwaysScroll,
				)}
			>
				{children}
			</DialogContent>
			<DialogActions className={styles.dialogActions}>
				<Button
					aria-label="button-negative"
					onClick={handleClose}
					color="secondary"
					variant="outlined"
				>
					{negative}
				</Button>
				{onResult && (
					<Button
						aria-label="button-positive"
						onClick={handleAcceptClick}
						variant="contained"
						color="primary"
						autoFocus
						disabled={inProgress}
					>
						{positive}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default BaseModal;
