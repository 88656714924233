import React, {FC} from 'react';

import {Menu, MenuItem} from '@mui/material';
import {Link} from 'react-router-dom';

import {NEOWIT_ORG_ID} from '../../constants/GlobalSettings';
import {useAuth} from '../../hooks/useAuth';
import useMenu from '../../hooks/useMenu';
import {User} from '../../interfaces/User';
import {UserCircle} from '../Icon';
import {anchorOrigin} from './common';
import MenuButton from './MenuButton';

export const AccountMenuButton: FC<{user: User | null}> = ({user}) => {
	const [anchorEl, handleClick, handleClose] = useMenu();
	const {orgId} = useAuth();
	const isNeowitMember = orgId === NEOWIT_ORG_ID;
	return (
		<>
			<MenuButton
				icon={<UserCircle />}
				onClick={handleClick}
				title="Profile"
			/>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={anchorOrigin}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={handleClose}
					component={Link}
					hidden={!user}
					to="/u/profile"
				>
					Profile
				</MenuItem>
				{isNeowitMember && (
					<MenuItem
						onClick={handleClose}
						component={Link}
						to="/u/profile/_flags"
					>
						Feature Flags
					</MenuItem>
				)}
				<MenuItem
					onClick={handleClose}
					component={Link}
					hidden={!user}
					to="/logout"
				>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
};
