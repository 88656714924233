/* eslint-disable no-param-reassign */
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import type {FeatureFlag} from './featureFlags';

export const FLAGS_KEY = 'neowit:flags';

type FlagsState = {
	flags: {
		[flag in FeatureFlag]?: boolean;
	};
};

const initialState: FlagsState = {
	flags: {},
};

export function getPreloadedFlagsState() {
	const flags = JSON.parse(localStorage.getItem(FLAGS_KEY) || 'null');
	return flags ?? initialState;
}

export const flagsSlice = createSlice({
	name: 'flags',
	initialState,
	reducers: {
		setFlags: (
			state,
			action: PayloadAction<Record<FeatureFlag, boolean | undefined>>,
		) => {
			state.flags = action.payload;
		},
		setFlag: (
			state,
			action: PayloadAction<{
				flag: FeatureFlag;
				value: boolean;
			}>,
		) => {
			state.flags[action.payload.flag] = action.payload.value;
		},
		clearFlag: (state, action: PayloadAction<FeatureFlag>) => {
			delete state.flags[action.payload];
		},
	},
});

export const {setFlags, setFlag, clearFlag} = flagsSlice.actions;

export default flagsSlice.reducer;

export const selectFlags = (state: {flags: FlagsState}) => state.flags.flags;
export const selectFlag = (flag: FeatureFlag) => (state: {flags: FlagsState}) =>
	state.flags.flags[flag];
